@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');
@import './appvars';

body {
  font-family: 'Nunito', Helvetica, Arial, sans-serif;
}

.PageContainer {
  min-height: calc(100vh - 2 * 56px - 48px);
}

input[type='text'],
textarea {
  outline: none;
  box-shadow: none !important;
  //border: 1px solid #ccc !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='number']:focus,
[type='text'].form-control:focus,
[type='password'].form-control:focus,
[type='email'].form-control:focus,
[type='tel'].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none; //inset 0 -1px 0 #ddd;
  border-color: #efefef; // #80bdff;
}

button:focus,
.btn:focus {
  outline: none !important;
}

.BlueButton {
  background: #5f84e1;
  margin: 7px;
  border-radius: 5px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  height: 60px;
  color: #ffffff !important;
  font-weight: 500;

  &:hover {
    opacity: 0.9;
  }
}

.form-control:focus {
  border-color: #ced4da !important;
  outline: none !important; //0;
  box-shadow: none !important; //0 0 0 0.2rem rgba(0,123,255,.25);
}

// SIDEBAR
// cf https://trendmicro-frontend.github.io/react-sidenav/

div[class*='sidenav---sidenav-navitem--'] {
  transition: 500ms ease;
}

div[class*='sidenav-subnav--'] {
  //position: static;
  float: left;
  clear: both;
  // position: absolute;
  left: 0;
  // top: 20px;
  border: none;
  width: 100%;
  background: transparent;
  overflow: hidden;
  box-shadow: none;
  color: white;
  transition: 500ms ease-in-out;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;

  &.Subnav-001 {
    top: 0px;
    position: absolute;
    left: 0;
  }

  &.Subnav-001.Open {
    top: 40px;
    position: absolute;
    left: 0;
    visibility: visible;
    opacity: 1;
  }
}

.Subnav-001 {
  position: static;
  float: left;
  clear: both;
  position: absolute;
  left: 0;
  top: 20px;
  border: none;
  width: 100%;
  background: transparent;
  overflow: hidden;
  box-shadow: none;
  color: white;
  transition: 300ms ease-in-out;
  display: block !important;
  position: absolute;
  left: 0;
  top: 20px;
  &.Open {
    top: 40px;
  }
}

.Subnav-001 > div:first-of-type {
  display: none;
}

.Subnav {
  //color: white !important;
  //background-color: #353f48 !important;
  display: block !important;
  position: absolute;
  left: 0;
  top: 20px;

  & .Open {
    top: 40px;
  }
}

// .SubnavItem {
//   div {
//     color: white !important;
//     &:hover {
//       background: #737171 !important;
//     }
//   }
//   color: #353f48 !important;
// }

// FORMIK ERROR COLOR
.field-error {
  color: #dc3545;
}

.UsersReactTable {
  .rt-thead.-header {
    background: #dcdcdc;
    color: #525252;
    border-radius: 5px; //5px 5px 0px 0;
    //padding: 10px;
  }

  .rt-th.rt-resizable-header {
    padding: 18px !important;
  }

  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 $color01 !important;
  }

  .rt-thead .rt-th.-sort-desc,
  .rt-thead .rt-td.-sort-desc {
    //box-shadow: inset 0 -3px 0 0 $color01 !important;
    box-shadow: inset 0 -3px 0 0 $color01 !important;
  }
}

.RatingsReactTable.ReactTable,
.GlobalRatingsReactTable.ReactTable {
  .-pagination {
    box-shadow: none;
    border-top: none;
    margin-top: 40px;
  }
  .-pagination .-btn {
    // background: #eeeeee;
    // color: #444242;
    color: #212529;
    color: #74b2fa;
    background: #ffffff;
    border-radius: 30px;
    max-width: 100px;
    margin: auto;
    border-radius: 30px;
    max-width: 100px;
  }

  .-pagination .-center {
    color: #7a8288;
  }

  .rt-th.rt-resizable-header {
    //padding: 18px !important;
    background: #eee;
    padding: 20px 0px;
    //border-radius: 10px;
  }

  .rt-thead .rt-th.-sort-desc,
  .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 $color01;
  }

  .rt-thead .rt-th.-sort-asc,
  .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 $color01;
  }
}

.GlobalRatingsReactTable.ReactTable {
  .-pagination {
    margin-top: 0px;
  }
}
