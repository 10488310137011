$color01: #5f84e1;
$color02: #e8305e;
$color03: #9f9f9f;
$backgroundColor01: #f3f8fd;
$backgroundColor02: #3bd6bf;
$backgroundColor03: #ff7b5a;
$backgroundColor04: #e44c7d;
$backgroundColor05: #36c1fc;
$backgroundColor06: #74b2fa;
$backgroundColor07: #dddddd;
$backgroundColor08: #eee;
$backgroundColorTextarea01: #f4f4f5;
$textcolor01: #415d80;
$textcolor02: #7a8288;
$errorcolor01: rgb(205, 44, 32);
